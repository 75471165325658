<template>
    <div class="avatar">
        <input type="file" :id="`img-${id}`" hidden accept="image/*" @change="onFileChange" />

        <cropper :src="image" @change="change" />

        <b-button
            v-if="added"
            native-type="button"
            class="avatar__edit"
            @click="choosePhoto">
            <svg-icon icon="edit"></svg-icon>
        </b-button>

        <b-button
            v-if="!added"
            native-type="button"
            class="avatar__add"
            @click="choosePhoto">
            <svg-icon icon="upload"></svg-icon>
        </b-button>
    </div>
</template>

<script>
import Icon from '@/components/Icon'
import { Cropper } from 'vue-advanced-cropper'

export default {
    name: 'CropImage',
    components: {
        'svg-icon': Icon,
        Cropper
	},
    props: {
        id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            image: null,
            file: null,
            added: false,
            crop: {
                width: 0,
                height: 0,
                x: 0,
                y: 0
            }
        }
    },
    methods: {
        choosePhoto() {
			document.getElementById(`img-${this.id}`).click()
		},
		async onFileChange(e) {
			const file = e.target.files[0]
			this.file = file
			this.image = await this.readFile(file)
		},
		change({ coordinates }) {
			this.added = true

			this.crop.width = coordinates.width
			this.crop.height = coordinates.height
			this.crop.x = coordinates.left
			this.crop.y = coordinates.top

            this.$emit('input', { file: this.file, crop: this.crop })
		},
        readFile(file) {
			return new Promise(resolve => {
				const reader = new FileReader()
				reader.addEventListener('load', () => resolve(reader.result), false)
				reader.readAsDataURL(file)
			})
		}
    }
}
</script>
